import { PageProps } from 'gatsby';
import compact from 'lodash/compact';
import { PageData } from 'packages/innedit';
import React, { FC } from 'react';

import Page from '~/components/List/Item/Page';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import params from '~/params/page.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useChannel from '~/utils/useChannel';

const PagesPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
  params: { channelId, espaceId },
}) => {
  const [channel, items] = useChannel({
    channelId,
    espaceId,
    pathname: location.pathname,
  });

  const model = new PageData({
    channelId,
    espaceId,
    params,
    orderDirection: 'desc',
    orderField: 'isHomePage',
    wheres: {
      parent: '',
    },
  });

  return (
    <TemplateEspace collectionName="pages" espace={espace} user={user}>
      <CMSView>
        <List
          allowSorting
          filters={compact([
            {
              label: 'Caché',
              multiple: true,
              name: 'hidden',
              options: [
                {
                  label: 'Oui',
                  value: true,
                },
                {
                  label: 'Non',
                  value: false,
                },
              ],
            },
          ])}
          itemList={Page}
          itemPathnamePrefix={`/espaces/${espaceId}/channels/${channelId}/pages/`}
          model={model}
          pathname={location.pathname}
          search={location.search}
          subMenu={items}
          title={channel?.label}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PagesPage);
